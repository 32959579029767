import React from "react";
import { viewports, colors } from "../../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller, mdOnly } = viewports;
const { white, black } = colors;

const ProductViewerStyles = () => (
  <style jsx>{`
    .product-viewer {
      position: relative;
    }
    .product-btn {
      width: 40px;
      height: 40px;
      background-color: #1769ff;
      border-radius: 64px;
      border: none;
      position: absolute;
      top: 50%;
      left: 70%;
    }
    .btn-circle {
      position: absolute;
      background-color: #1769ff;
      width: 16px;
      height: 16px;
      border-radius: 32px;
      border: solid 1px ${colors.white};
      display: block;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      background: rgba(23, 105, 255, 0.4);
    }
    .product-item {
      max-width: 100%;
      max-height: 400px;
      position: relative;
      display: table;
      margin: 0 auto;
    }
    .product-img {
      min-width: 150px;
      max-width: 390px;
    }
    #product-viewer-popover .ant-popover-inner {
      border-radius: 16px !important;
      font-family: "sofia-pro", sans-serif !important;
    }
    #product-viewer-popover .ant-popover-title {
      min-width: 177px;
      min-height: 32px;
      margin: 0;
      padding: 16px 16px 0 16px;
      color: #090d12 !important;
      border-bottom: none;
      font-size: 18px !important;
      font-weight: 700 !important;
    }
    #product-viewer-popover .ant-popover-inner-content {
      padding: 0 16px 16px 16px;
      color: rgba(0, 0, 0, 0.85);
      max-width: 226px;
    }
    .product-viewer .ant-pop-p {
      color: #748093;
    }
    .a {
      grid-area: a;
      left: 10%;
      top: 0%;
      width: 330px;
      height: 330px;
    }
    .b {
      grid-area: b;
      position: absolute;
      right: 17%;
      top: 55%;
      width: 187px;
      height: 187px;
    }
    .c {
      grid-area: c;
      position: absolute;
      left: 106px;
      top: 30%;
      width: 330px;
      height: 330px;
    }
    .d {
      grid-area: d;
      position: absolute;
      left: 25%;
      top: 0%;
      width: 205px;
      height: 205px;
    }
    .e {
      grid-area: e;
      position: absolute;
      right: 106px;
      top: 0%;
      width: 205px;
      height: 205px;
    }
    .f {
      grid-area: f;
      left: -5%;
      bottom: 0%;
      width: 205px;
      height: 205px;
    }
    @media screen and (max-width: 1366px) {
      .product-viewer {
        position: relative;
      }
    }
    @media screen and (max-width: 1280px) {
      .a,
      .c {
        width: calc(330px - 10%);
      }
      .d,
      .e,
      .f {
        width: calc(205px - 10%);
      }
    }
    @media ${mdOnly} {
      .a {
        left: 5%;
      }
    }
    @media ${mdOrSmaller} {
      .product-cont {
        margin: 32px auto;
      }
      .a {
        left: 5%;
      }
      .a,
      .c {
        width: calc(330px - 20%);
      }
      .d,
      .e,
      .f {
        width: calc(205px - 20%);
      }
      .product-btn {
        top: 30%;
        left: 50%;
      }
    }
    @media ${smOrSmaller} {
      .product-intro {
        padding: 0;
      }
      .hidden {
        display: none;
      }
      .no-margin-bottom {
        margin-bottom: 0;
      }
      .product-cont {
        display: block;
        max-height: 1200px;
      }
      .a,
      .b,
      .c,
      .d,
      .e,
      .f {
        top: auto;
        left: auto;
        right: auto;
        position: relative;
        width: 100%;
      }
      .d,
      .e,
      .f {
        display: none;
      }
      .b {
        transform: translateX(30%) translateY(-30px) !important;
      }
      .c {
        padding-left: 16px;
      }
      .product-img {
        width: 250px !important;
      }
      .product-intro {
        text-align: center;
        margin: 0;
      }
      .special-product {
        transform: translateX(30%);
        margin: 0 !important;
      }
      .product-item {
        margin: 0 auto;
      }
      .btn-circle,
      .product-btn {
        display: none;
      }
    }
  `}</style>
);

export default ProductViewerStyles;

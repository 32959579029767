import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Popover, Button } from "antd";
import { motion, useAnimation } from "framer-motion";
import { isMobileOnly, isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import Image from "../../ImageQuerys/RandomImages";
import ProductViewerStyles from "./ProductViewerStyles";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";
import CtaSecondary from "../../Buttons/secondary-cta";

// Varients
const container = {
  show: {
    transition: {
      staggerChildren: 0.35,
      delay: 1,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: 200,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1.6,
    },
    exit: {
      opacity: 0,
      y: -200,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  },
};

const title = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};
const content = (data) => {
  return (
    <div className="max-w-[200px] mb-1">
      <p className="font-sans text-black-300 leading-[150%] text-[14px]">
        {data.description}
      </p>
      <CtaSecondary ctaTitle="View more" target={data.link} />
    </div>
  );
};

const ProductViewer = (props) => {
  const { sectionData, bgDark } = props;
  const controls = useAnimation();
  const titleControls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("show");
      titleControls.start("show");
    }
    if (!inView) {
      controls.start("exit");
      titleControls.start("exit");
    }
  }, [controls, inView, titleControls]);
  return (
    <section
      className={`product-viewer z-0 pt-10 lg:pt-16 border-box w-full ${
        bgDark ? "bg-black" : "bg-white"
      }`}
    >
      <Image
        imageName="half-elipse.png"
        style={{ zIndex: -1, position: "absolute" }}
        className="absolute left-0 -top-32 lg:-top-10 w-40 lg:w-64"
      />
      <motion.div
        variants={title}
        initial={isMobileOnly ? "show" : "hidden"}
        animate={titleControls}
        ref={ref}
        className="z-50 lg:max-w-[840px] mx-4 lg:mx-auto text-center mb-6 z-10"
      >
        <h2 className="text-white text-h2-small font-bold mb-8 lg:mb-10">
          {sectionData.introTitle}
          <span className="text-primary">.</span>
        </h2>
        <p className="mb-12 lg:mb-10 text-black-600 text-p lg:text-p-large">
          {sectionData.introSub}
        </p>
        <CtaPrimary
          title="View all products"
          className="mx-auto"
          target={sectionData.link}
          large
        />
      </motion.div>
      <div className="overflow-hidden ">
        <motion.div
          ref={ref}
          variants={container}
          initial={isMobileOnly ? "show" : "hidden"}
          animate={controls}
          exit="exit"
          className="relative pb-0 lg:px-[106px] lg:mx-auto max-w-1440 w-full border-box"
        >
          {sectionData.products.map((i, idx) => (
            <motion.div
              key={`${i.img}__${idx.toString()}`}
              className={`product-item ${isMobile && idx >= 3 && "hidden"} ${
                i.area
              }`}
              variants={item}
            >
              <Image imageName={i.img} className="product-img" />
              <Popover
                content={() => content(i.popData)}
                trigger="click"
                placement="bottom"
                title={i.popData.name}
                className="font-sans"
                id="product-viewer-popover"
                style={{ borderRadius: 16 }}
              >
                <button type="button" className="product-btn">
                  <div className="relative">
                    <span className="btn-circle" />
                  </div>
                </button>
              </Popover>
            </motion.div>
          ))}
        </motion.div>
      </div>
      <ProductViewerStyles />
    </section>
  );
};

ProductViewer.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  bgDark: PropTypes.bool,
};
ProductViewer.defaultProps = {
  sectionData: {},
  bgDark: true,
};

export default ProductViewer;
